import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'

import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    paragraph: {
        paddingBottom: "2rem",
    },
}));

export const TermsPageTemplate = () => {
    
    const classes = useStyles();

    return(
    <Container>
        <Typography variant="h3" component="h1" className={classes.paragraph}> General Terms of Use</Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Last Updated November 26, 2020
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Welcome to DanceClarity
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            THE TERMS AND CONDITIONS SET FORTH BELOW (“TERMS OF SERVICE”) GOVERN YOUR USE OF THE DANCECLARITY WEB APPLICATION PLATFORM SERVICES (COLLECTIVELY, THE “SERVICES”). 
            THESE TERMS ARE A LEGAL CONTRACT BETWEEN YOU AND DANCECLARITY AND GOVERN YOUR ACCESS TO, AND USE OF, THE SERVICES AND THE DANCECLARITY WEBSITES, INCLUDING 
            WWW.DANCECLARITY.COM, APP.DANCECLARITY.COM and DEV.DANCECLARITY.COM (THE “SITES”). IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, DO NOT ACCESS OR OTHERWISE 
            USE THE SITES, THE SERVICES OR ANY INFORMATION CONTAINED ON THIS SITE. YOUR USE OF THIS SITE AND/OR THE SERVICES SHALL BE DEEMED TO BE YOUR AGREEMENT TO 
            ABIDE BY EACH OF THE TERMS SET FORTH BELOW. DANCECLARITY MAY UPDATE OR REVISE THE TERMS OF THIS AGREEMENT WITHOUT NOTICE AT ANYTIME. PLEASE REVIEW THE SITE 
            ON A REGULAR BASIS TO OBTAIN TIMELY NOTICE OF ANY REVISIONS. IF YOU CONTINUE TO USE THE SERVICES AFTER THE REVISIONS TAKE EFFECT, YOU AGREE TO BE BOUND BY 
            THE REVISED TERMS. YOU AGREE THAT WE SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY MODIFICATION OF THE TERMS.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            NOTE: THIS AGREEMENT CONTAINS A BINDING ARBITRATION PROVISION AND TIME LIMIT ON SUBMITTING CLAIMS THAT AFFECT YOUR RIGHTS. PLEASE READ THESE TERMS AND 
            CONDITIONS CAREFULLY.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity will provide the Services, and you may access and use the Services, in accordance with this Agreement. If you order Services through an 
            order form or SaaS Services and Support Agreement (each an “Order Form”), the Order Form may contain additional terms and conditions and information 
            regarding the Services you are ordering. Unless otherwise expressly set forth in any such additional terms and conditions applicable to the specific 
            Services which you choose to use, those terms and conditions are hereby incorporated into this Agreement in relation to your use of those Services.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            General Use Restrictions
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            You may subscribe to and use the Services as either a personal user (a "student") or a business user (a "business user"). If you are a business user,
            you may only use the Services for your internal business purposes, and you agree, if you are an individual, that the Services are being purchased in 
            a business or professional capacity. If you are using the Services as a student, you agree that you will only provide information and use the Services 
            while acting on your own behalf and not on behalf of a business or a business user.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            As part of the Services: (i) DanceClarity may provide certain documents, manuals and information, help documentation, syllabus information, content, 
            logos, graphics and images (together, the “Content”); and (ii) you may create or upload to your account your own content or third party content for 
            which you have all necessary permissions (“User Content”). If you are the individual that initially creates a business user account, you are the 
            “Manager” for that business user account. As the Manager, you may invite other users to collaborate with you using your account (“Authorized User”). 
            All Managers and Authorized Users must abide by the DanceClarity Terms of Use. Managers are responsible for any and all privileges that are granted 
            to each of its Authorized Users and liable for its Authorized Users’ breach of these Terms of Use.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            You acknowledge and agree that DanceClarity may remove all or a part of any Content or User Content from the Services in its sole discretion.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            You shall use the Services exclusively for authorized and legal purposes, consistent with all applicable laws, rules, and regulations of the United States
            as well as your country of residence. You agree that you will not, and will not permit any Authorized User or other third party to: (a) copy, modify, 
            adapt, alter, translate, display or use the Services, except as expressly allowed herein or in the applicable documentation; (b) sublicense, lease, rent, 
            loan, distribute, resell, provide access to the Services on a time-share or service bureau basis, or otherwise transfer the Services, including, as 
            applicable, the Content, the User Content, documentation, software, associated user interfaces, help resources, and any related technology or services 
            DanceClarity makes available via the Services and the internet, and all updates and upgrades thereto, if any, to any third party; (c) reverse engineer, 
            decompile, disassemble, or otherwise derive or determine or attempt to derive or determine the source code (or the underlying ideas, algorithms, 
            structure or organization) of the Services; (d) contest or do or aid others in contesting or doing anything which impairs the validity of any 
            proprietary or intellectual property rights, title, or interest of DanceClarity in and to the Services; (e) obliterate, alter, or remove any proprietary 
            or intellectual property notices from the Services in physical or electronic form; (f) use the Services to interfere with, gain unauthorized access 
            to, or otherwise violate the security of DanceClarity’s or another party’s server, network, network access, personal computer or control devices, 
            software or data, or other system, or to attempt to do any of the foregoing; (g) use the Services to violate any applicable law, statute, ordinance or 
            regulation; or (h) use the Services to transmit, publish, or distribute any material or information (1) that contains a computer virus or other code, 
            files, or programs designed to disrupt or interfere with the functioning of the Services, (2) for which you do not have all necessary rights and 
            licenses, including any material or information that infringes, violates, or misappropriates the intellectual property rights of any third party, 
            or (3) that is or that may reasonably be perceived as being harmful, threatening, offensive, obscene, or otherwise objectionable.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Limited License to Access and Use the Services, Content
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            If you are a business user, subject to the terms and conditions herein, DanceClarity grants you a limited, non-exclusive, non-transferable (except as 
            expressly permitted by this Agreement) right to access and to use the Services for your company’s internal business purposes. With respect to the 
            DanceClarity software and Sites (collectively, the “Applications”), DanceClarity grants you a limited, non-exclusive and non-transferable right to 
            access, download and use the Applications solely for your internal business use. 
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            If you are a student, subject to the terms and conditions herein, DanceClarity grants you a limited, non-exclusive, non-transferable (except as 
            expressly permitted by this Agreement) right to access and to use the Services for your personal, non-commercial purposes. With respect to the DanceClarity 
            Applications, DanceClarity grants you a limited, non-exclusive and non-transferable right to access, download and use the Applications solely for your personal,
            non-commercial purposes.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Except for the rights set forth in the preceding sentences above, you acknowledge and agree that you have no right to modify, edit, copy, reproduce, 
            create derivative works of, reverse engineer, alter, enhance or in any way exploit any of the Services or Applications in any manner. You shall not 
            distribute the Services, access to the Services or Applications in any manner to any third party. Except as stated herein, you acknowledge that you 
            have no right, title or interest in or to the Services or Applications.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            If you are or become a competitor of DanceClarity, you may not access or use the Services without DanceClarity’s explicit, advance, written consent, 
            and then only for the purposes authorized in writing.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Except for the limited use license above, you acknowledge and agree that you have no right to modify, edit, copy, reproduce, create derivative works 
            of, reverse engineer, alter, enhance or in any way exploit any of the Content, the Sites or the Services in any manner. You shall not distribute 
            the Content or the Services in any manner to any third party.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            User Content
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            If you have uploaded User Content on the Services, you retain all rights that you possess to your User Content. You grant to DanceClarity, a 
            non-exclusive, worldwide, perpetual license to host and make the User Content available on the Services in accordance with the access rights 
            that you select, including making certain identifying information available to other students and business users. If you are a business user, 
            you grant to your Authorized Users who access your account, a non-exclusive, worldwide, perpetual 
            license to use User Content for their personal or business use, in accordance with the levels of permission you set. If you are a student and 
            you grant any business user access to your User Content, you grant to such business user a non-exclusive, worldwide, perpetual 
            license to use User Content for their business use.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity will maintain reasonable physical and technical safeguards to prevent unauthorized disclosure of or access to User Content, in 
            accordance with industry standards, but DanceClarity does not guarantee the security of any User Content. DanceClarity cannot and does not 
            undertake to screen, review, edit, censor and/or otherwise filter 
            and/or control User Content and/or the behavior of users of User Content and/or the Services. DanceClarity may, but is not obliged to, review, 
            either by manual and/or automated means, all User Content which is and/or may be uploaded on the Services, and monitor and/or review any areas 
            of the Services where users transmit and/or post communications or communicate with each other or DanceClarity (as applicable). DanceClarity 
            reserves the right (but has no obligation) to review any User Content, and to investigate and/or take appropriate action against you in our sole 
            discretion if you violate the Documentation and/or otherwise create liability for us or any other person. Such action may include without limitation 
            removing your User Content, terminating your Account, and/or reporting you to law enforcement authorities.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DANCECLARITY DISCLAIMS ALL EXPRESS, IMPLIED AND STATUTORY WARRANTIES WITH REGARD TO ALL USER CONTENT, INCLUDING, BUT NOT LIMITED TO, ALL IMPLIED 
            WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. DANCECLARITY DISCLAIMS ANY LIABILITY OF ANY KIND WITH REGARD TO ALL USER CONTENT, 
            INCLUDED, WITHOUT LIMITATION, INFRINGEMENT OF A THIRD PARTY’S INTELLECTUAL PROPERTY.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Term and Termination
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            All rights to use the Services will continue until or unless terminated by DanceClarity at its sole discretion. DanceClarity reserves the right to 
            terminate access to the Services for any reason and without notice. Notwithstanding the prior, if you are a Manager and have a fully paid 
            subscription, DanceClarity will provide notice of termination or suspension and, if the termination is not the result of your violation of these Terms of Use,
            DanceClarity will provide you with a pro-rated refund of fees for the unused portion of the applicable subscription term. The Manager may request a 
            copy of the User Content for a period of 30 days following the date of termination.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Authorized Users
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            You may be given access to certain password protected areas of the Sites and the Services. You are responsible for maintaining the confidentiality 
            of your password, registration data and for any and all activity that occurs under your password. You agree to notify DanceClarity immediately of 
            any unauthorized use of your password or any other breach of security relating to the Sites and the Services. Managers may invite Authorized Users 
            to collaborate on their account. Managers and Authorized Users may have different levels of access to certain account settings or User Content, and
            Managers should closely review and understand the areas and content to which Authorized Users have access. Managers agree to comply with the 
            conditions and restrictions set forth in the Section titled “Your Responsibilities.” You shall not, and shall not attempt to, access any data of 
            another DanceClarity account holder if you are not an Authorized User under the applicable account.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Privacy Policy
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Your use of the Sites and the Services is governed by the DanceClarity Privacy Policy (the “Privacy Policy”), which may be found at 
            <Link to='/privacy'>https://www.danceclarity.com/privacy</Link>.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Payment and Electronic Communications
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Paid subscriptions are payable in advance for the applicable subscription period that you select. If you purchase a paid subscription, you are 
            expressly agreeing that DanceClarity is permitted to bill you the applicable fees, any applicable tax and any other charges you may incur with 
            DanceClarity in connection with your purchase on the Sites (“Charges”). The Charges will be billed to the credit card or other account that you provide 
            in accordance with the billing terms in effect at the time the Charges are due and payable or directly invoiced. If payment is not received or cannot 
            be charged to your credit card or other account for any reason in advance, DanceClarity reserves the right to either suspend or terminate your access 
            to your account and the Services offered through the Sites and terminate these Terms of Service. All purchases are non-cancellable and all charges are 
            non-refundable except as otherwise set forth herein.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            If you have purchased a paid subscription to the Services, your subscription will automatically renew for additional, successive subscription periods 
            equal in duration to the initial subscription period unless you have terminated your subscription prior to the next renewal period. You may terminate 
            your subscription at any time and you will not be obligated to pay any additional fees for upcoming subscription renewal periods but any subscription 
            fees you have paid in advance will not be refunded.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            You acknowledge and agree that DanceClarity will automatically charge your credit card or other account on record with DanceClarity for the then-current 
            subscription period upon the commencement of any renewal period.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            By using the Site, you consent to receiving electronic communications from DanceClarity, including communications via text message or automated telephone
            message. These electronic communications may include notices about applicable fees and charges, transactional information and other information concerning or related to the Services. These electronic communications 
            are part of your relationship with DanceClarity and you receive them as part of your purchase. You agree that any notices, agreements, disclosures 
            or other communications that DanceClarity sends you electronically will satisfy any legal communication requirements, including that such communications 
            be in writing.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            The Services may also permit users to both send and receive communications with one another directly or via messages from DanceClarity on such user's 
            behalf, including communications via text message or automated telephone message. As part of using the Services, you consent to receiving these electronic communications from
            DanceClarity and the other users.  
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Third Party Content
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Certain information and other content appearing in advertisements or sponsorships on the Services may be the material of third party licensors and 
            suppliers to DanceClarity (“Third Party Content”). Third Party Content does not include User Content. The Third Party Content is, in each case, the 
            copyrighted and/or trademarked work of the creator/licensor. You agree to only display the Third Party Content on your computer solely for your 
            personal use or business use. You acknowledge and agree that you have no right to download, cache, reproduce, modify, display (except as set forth 
            in this paragraph), edit, alter or enhance any of the Third Party Content in any manner unless you have permission from the owner of the Third Party 
            Content. DANCECLARITY DISCLAIMS ALL EXPRESS, IMPLIED AND STATUTORY WARRANTIES AND CONDITIONS WITH REGARD TO THIRD PARTY CONTENT, INCLUDING, BUT NOT 
            LIMITED TO, ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            The Sites may be linked to other sites that are not the Sites. DanceClarity is providing these links to you only as a convenience, and DanceClarity is 
            not responsible for such linked sites, including, without limitation, the content or links displayed on such sites.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Your Responsibilities
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            If you use software tools to import content from other sources on the Internet to create User Content, you agree that you have all necessary rights 
            and permissions to use such content and to create the User Content using that third party content and that you will only submit and use User Content 
            in any account that you register for or have access to in a manner that complies with all applicable laws and regulations. You further agree that you 
            will not upload, post or otherwise make available on the Sites any material protected by copyright, trademark, or any other proprietary right without 
            the express permission of the owner of such copyright, trademark or other proprietary right owned by a third party, and the burden of determining 
            whether any material is protected by any such right is on you. You shall be solely liable for any damage resulting from any infringement of copyrights, 
            trademarks, proprietary rights, or any other harm resulting from any Submission and/or User Content that you make. You represent and warrant that: 
            (i) you own all submissions (“Submissions”) and/or User Content posted by you on or through the Services or otherwise have the right to grant the 
            licenses to DanceClarity set forth in this section, and (ii) the posting of your Submissions and/or User Content on or through the Services does not 
            violate the privacy rights, publicity rights, trademark rights, copyrights, contract rights or any other rights of any person or entity. You agree to 
            pay for all royalties, fees, damages and any other monies owing any person by reason of any Submissions and/or User Content posted by you to or through 
            the Services. DanceClarity shall have no obligation to pay you any fees or royalties for your Submissions and/or User Content.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            You are responsible for the activities of all end users, whether Authorized Users or not, who access or use the Services through your account and you 
            agree to ensure that any such end user will comply with the terms of this Agreement and any DanceClarity policies. DanceClarity assumes no 
            responsibility or liability for violations. If you become aware of any violation of this Agreement in connection with use of the Services by any 
            person, please contact DanceClarity at contact@danceclarity.com. DanceClarity may investigate any complaints and violations that come to its attention 
            and may take any (or no) action that it believes is appropriate, including, but not limited to issuing warnings, removing the content or terminating 
            accounts and/or user profiles. Under no circumstances will DanceClarity be liable in any way for any data or other content viewed while using the 
            Services, including, but not limited to, any errors or omissions in any such data or content, or any loss or damage of any kind incurred as a result of 
            the use of, access to, or denial of access to any data or content.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            YOU EXPRESSLY ACKNOWLEDGE THAT BY ALLOWING THE USE OF THE SERVICES, DANCECLARITY IS NOT PROVIDING LEGAL, ACCOUNTING, TAX OR ANY OTHER PROFESSIONAL 
            SERVICES AND THAT YOU ARE SOLELY RESPONSIBLE FOR SOLICITING ANY PROFESSIONAL ADVICE YOU DEEM NECESSARY IN THE COURSE OF USING THE SERVICES.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Unauthorized Activities
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Unauthorized use of any User Content, the Services or Third Party Content contained on the Site may violate certain laws and regulations. You agree to 
            indemnify and hold DanceClarity and its officers, directors, employees, affiliates, agents, licensors, and business partners harmless from and against 
            any and all costs, damages, liabilities, and expenses (including attorneys’ fees and costs of defense) that DanceClarity or any other indemnified party 
            suffers in relation to, arising from, or for the purpose of avoiding, any claim or demand from a third party based on your submitted User Content and/or 
            that your use of the Services or Third Party Content or the use by any person using your user name and/or password violates any applicable law or 
            regulation, or the copyrights, trademark rights or other rights of any third party. DanceClarity may report to law enforcement authorities any actions 
            that may be illegal, and any reports it receives of such conduct. When legally required or at DanceClarity’s discretion, DanceClarity will cooperate 
            with law enforcement agencies in any investigation of alleged illegal activity on the Site, the Services or on the Internet.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Proprietary Rights
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity and/or its licensors, as applicable, retain ownership of all proprietary rights in the Services and in all trade names, trademarks, 
            service marks, logos, and domain names (“DanceClarity Marks”) associated or displayed with the Services. You may not frame or utilize framing 
            techniques to enclose any DanceClarity Marks, or other proprietary information (including images, text, page layout, or form) of DanceClarity without 
            express written consent. You may not use any metatags or any other “hidden text” utilizing DanceClarity Marks without DanceClarity’s express written 
            consent. Other trademarks, names and logos on the Site are the property of their respective owners.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Unless otherwise specified in these Terms of Use, all information and screens appearing on the Site and through the Services, including documents, 
            Services, site design, text, graphics, logos, images and icons, as well as the arrangement thereof, are the sole property of DanceClarity, its 
            licensors or owners of third-party links. All rights not expressly granted herein are reserved. Except as otherwise required or limited by applicable 
            law, any reproduction, distribution, modification, retransmission, or publication of any copyrighted material is strictly prohibited without the 
            express written consent of the copyright owner or license.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Copyright and Trademark Infringements
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DanceClarity may, in appropriate circumstances and at our discretion, terminate access to the Site to users who infringe the intellectual property 
            rights of others. If you believe that your work is the subject of copyright infringement and/or a trademark infringement and appears on the Site, 
            please contact DanceClarity at contact@danceclarity.com. Only the intellectual property rights owner may report potentially infringing items 
            through DanceClarity’s reporting system set forth above. If you are not the intellectual property rights owner, you should contact the intellectual 
            property rights owner and they can choose whether to use the procedures set forth in these Terms of Use.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Disclaimer of Warranties
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            YOUR USE OF THIS SITE AND/OR THE SERVICES IS AT YOUR OWN RISK. THE USER CONTENT, THE SERVICES, THE THIRD PARTY CONTENT AND SUBMISSIONS HAVE NOT BEEN 
            VERIFIED OR AUTHENTICATED IN WHOLE OR IN PART BY DANCECLARITY, AND THEY MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL OR OTHER ERRORS. DANCECLARITY DOES 
            NOT WARRANT THE ACCURACY OR TIMELINESS OF THE USER CONTENT, SUBMISSIONS OR THE THIRD PARTY CONTENT CONTAINED ON THIS SITE. DANCECLARITY HAS NO LIABILITY 
            FOR ANY ERRORS OR OMISSIONS IN THE USER CONTENT, SUBMISSIONS AND/OR THE THIRD PARTY CONTENT, WHETHER PROVIDED BY DANCECLARITY OR OUR LICENSORS.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DANCECLARITY, FOR ITSELF AND ITS LICENSORS, MAKES NO EXPRESS, IMPLIED OR STATUTORY REPRESENTATIONS, WARRANTIES, OR GUARANTEES IN CONNECTION WITH THE 
            SITES, THE SERVICES, ANY MATERIALS, SUBMISSIONS, USER CONTENT, USER SITES, OR THIRD PARTY CONTENT, RELATING TO THE QUALITY, SUITABILITY, TRUTH, ACCURACY 
            OR COMPLETENESS OF ANY INFORMATION OR MATERIAL CONTAINED OR PRESENTED ON THE SITES AND/OR THE RESULTS OBTAINED FROM THE USE OF THE SITES, INCLUDING 
            WITHOUT LIMITATION THE MATERIALS, SUBMISSIONS, USER SITES, AND THE THIRD PARTY CONTENT. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SITES, 
            THE SERVICES, MATERIALS, SUBMISSIONS, USER CONTENT, THIRD PARTY CONTENT, AND ANY INFORMATION OR MATERIAL CONTAINED OR PRESENTED ON THIS SITE IS PROVIDED 
            TO YOU ON AN “AS IS,” “AS AVAILABLE” AND “WHERE-IS” BASIS WITH NO WARRANTY OF IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR 
            NON-INFRINGEMENT OF THIRD PARTY RIGHTS. DANCECLARITY DOES NOT PROVIDE ANY WARRANTIES AGAINST VIRUSES, SPYWARE OR MALWARE THAT MAY BE INSTALLED ON YOUR 
            COMPUTER. DANCECLARITY DOES NOT GUARANTEE ANY UPTIME OR AVAILABILITY OF THE SERVICES.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Limitation of Liability
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            DANCECLARITY SHALL NOT BE LIABLE TO YOU FOR ANY DAMAGES RESULTING FROM YOUR DISPLAYING, COPYING, OR DOWNLOADING ANY OF THE MATERIALS, USER CONTENT, OR 
            THIRD PARTY CONTENT TO OR FROM THIS SITE OR THE SERVICES OR FROM THE USE OF THE SERVICES. DANCECLARITY DISCLAIMS ALL LIABILITY RELATING TO ANY USER 
            CONTENT OR THE SERVICES, INCLUDING ANY ERROR, VIRUS, DEFAMATION, LIBEL, OBSCENITY OR INACCURACY CONTAINED IN ANY USER CONTENT, WHETHER OR NOT ARISING 
            UNDER THE LAWS OF COPYRIGHT, LIBEL, PRIVACY OR OTHERWISE, ANY PROHIBITED USER CONTENT AND ANY OTHER USER CONTENT. YOU ARE SOLELY RESPONSIBLE FOR ANY 
            DAMAGES (INCLUDING WITHOUT LIMITATION TO THE SERVICES) RESULTING FROM USE (AND/OR SUBMISSION) OF ANY USER CONTENT AND/OR THE SERVICES (INCLUDING WITHOUT 
            LIMITATION DISPUTES AND INCIDENTS DESCRIBED IN THE PRECEDING SECTIONS) AND RELATED TRANSACTIONS AND/OR OCCURRENCES. DANCECLARITY SHALL HAVE NO 
            RESPONSIBILITY FOR UNAUTHORIZED ACCESS TO YOUR ACCOUNT.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            IN NO EVENT SHALL DANCECLARITY BE LIABLE TO YOU FOR ANY INDIRECT, EXTRAORDINARY, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES 
            (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) HOWEVER ARISING, EVEN IF DANCECLARITY KNOWS THERE IS A POSSIBILITY OF SUCH 
            DAMAGE. FURTHERMORE, IN NO EVENT SHALL DANCECLARITY BE LIABLE FOR ANY DAMAGES IN AN AMOUNT MORE THAN THE FEES PAID BY YOU TO DANCECLARITY IN THE PRIOR 
            12 MONTHS.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Indemnification
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            You agree to indemnify, defend and hold harmless DanceClarity, its affiliates, officers, directors, employees, consultants, agents, suppliers and 
            resellers from any and all third party claims, liability, damages and/or costs (including, but not limited to, attorneys’ fees) arising from your use 
            of the Services, your violation of these Terms of Use and the infringement or violation by you or any other user of your account, of any intellectual 
            property or other right of any person or entity or applicable law. DanceClarity reserves the right to assume the exclusive defense and control of any 
            matter otherwise subject to indemnification by you, in which event you will assist and cooperate with us in asserting any available defenses. You agree 
            not to settle any matter without the prior written consent of DanceClarity. DanceClarity will use reasonable efforts to notify you of any such claim, 
            action or proceeding upon becoming aware of it.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Local Laws; Export Control
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            User Sites and/or Third Party Content may not be appropriate or available for use outside of the United States of America. If you use the Site or the 
            Services outside the United States of America, you are responsible for following applicable local laws and regulations, including any applicable export 
            controls laws. More specifically, you acknowledge that the Services, or portion thereof may be subject to the export control laws of the United States 
            and other applicable country export control and trade sanctions laws (“Export Control and Sanctions Laws”). You and your Authorized Users and other end 
            users accesses the Services through your account, may not access, use, export, re-export, divert, transfer or disclose any portion of the Services or 
            any related technical information or materials, directly or indirectly, in violation of any applicable export control or trade sanctions law or 
            regulation. You represent and warrant that (i) you and your end users are not citizens of, or located within, a country or territory that is subject to 
            U.S. trade sanctions or other significant trade restrictions (including without limitation Cuba, Iran, North Korea, Syria, and the Crimea) and that you 
            and your end users will not access or use the Services, or export, re-export, divert, or transfer the Services, in or to such countries or territories; 
            (ii) you and your end users are not identified on any U.S. government restricted party lists (including without limitation the U.S. Treasury Department’s 
            List of Specially Designated Nationals and Blocked Persons and Foreign Sanctions Evaders List, the U.S. Department of Commerce’s Denied Parties List, 
            Entity List, and Unverified List, and the U.S. Department of State proliferation-related lists); and (iii) that no User Content created or submitted by 
            you or your end users is subject to any restriction on disclosure, transfer, download, export or re-export under the Export Control Laws. You are solely 
            responsible for complying with the Export Control Laws and monitoring them for any modifications.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Dispute Resolution and Binding Arbitration Provision
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Definitions. As used in this Arbitration Provision, “Claims” means all claims, disputes, or controversies between you and us of any nature or kind, 
            whether pre-existing, present, or future, that arise from or relate to the Services. This includes but is not limited to disagreements about the 
            validity, enforceability, or scope of this Arbitration Provision.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Informal Efforts to Resolve Dispute. If a dispute arises between you and DanceClarity, you should first attempt to resolve it by contacting 
            DanceClarity at contact@danceclarity.com. DanceClarity will attempt in good faith to resolve all Claims submitted this way within fifteen (15) days 
            of receipt.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            YOU MUST FILE ANY LEGAL COMPLAINT AGAINST DANCECLARITY WITH A PERMITTED COURT WITHIN ONE (1) YEAR OF THE DATE OF THE OCCURRENCE OF THE EVENT OR FACTS 
            GIVING RISE TO A CLAIM, OR YOU WAIVE THE RIGHT TO PURSUE ANY CLAIM BASED UPON SUCH EVENT, FACTS OR DISPUTE.
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            These Terms may be changed, modified, supplemented or updated by DanceClarity from time to time. If DanceClarity makes changes these 
            Terms, your continued use of the Services shall serve as your acceptance to the new terms. If DanceClarity makes a material change to these Terms of Use 
            that will affect your existing paid subscription, and you do not agree to the change, you may notify DanceClarity at contact@danceclaity.com within 
            thirty (30) days after receiving notice of the change. If you notify DanceClarity as required, then you may receive a pro-rated refund of any already 
            paid subscription fees or you may remain governed by the Terms of Use in effect immediately prior to the change until the end of your current 
            subscription for the affected Services. If the affected purchased Services are renewed, it will be renewed under DanceClarity’s then current Terms of Use. 
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            Force Majeure
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Except for payment obligations, neither DanceClarity nor you shall be liable by reason of any failure or delay in the performance of its obligations 
            on account of events beyond the reasonable control of a party, which may include but not be limited to acts of god, war, strikes, government regulation, 
            executive orders or advisory (including travel advisory warning by the government or World Health Organization), disease outbreaks, civil disturbance, 
            terrorism or threats of terrorism in the United States as substantiated by governmental warnings or advisory notices, curtailment of transportation, 
            disaster fire, earthquakes, hurricanes, unseasonable extreme inclement weather, shortages or disruption of the electrical power supply causing blackouts 
            or rolling blackouts, or any other comparable condition, commercially impracticable, illegal, or impossible to fully perform under this Agreement as 
            the parties originally contracted (a “Force Majeure Event”). In the case of a Force Majeure Event lasting more than thirty days, either party may 
            terminate this Agreement, without liability, upon written notification to the other party.
        </Typography>
        <Typography variant="h4" component="h2" className={classes.paragraph}>
            General
        </Typography>
        <Typography variant="body1" component="p" className={classes.paragraph}>
            Utah law and controlling U.S. federal law, without regard to the choice or conflicts of law provisions, will govern these Terms of Use. Any claim 
            brought against DanceClarity under these Terms of Use shall be brought in a court of competent jurisdiction in the state of Utah. If any of these 
            Terms of Use is found to be inconsistent with applicable law, then such term shall be interpreted to reflect the intentions of the parties, and no 
            other terms will be modified. DanceClarity’s failure to enforce any of these Terms is not a waiver of such term. If any part of the Documentation is 
            held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining portions will remain in full 
            force and effect. A waiver by either party of any term or condition of the Documentation and/or any breach thereof, in any one instance, will not 
            waive such term and/or condition or any subsequent breach thereof. You may not assign your rights under the Documentation to any other party without 
            DanceClarity’s express prior written consent; DanceClarity may assign its rights under the Documentation without condition. The Documentation will 
            be binding upon and will inure to the benefit of the parties, their successors and permitted assigns. These Terms of Use are the entire agreement 
            between you and DanceClarity and supersede all prior or contemporaneous negotiations, discussions or agreements between you and DanceClarity about 
            the Sites and the Services. The proprietary rights, disclaimer of warranties, representations made by you, indemnities, limitations of liability 
            and general provisions shall survive any termination of these Terms.
        </Typography>
    </Container>
    )
}

const TermsPage = () => {

    
    return (
        <Layout>
            <TermsPageTemplate/>
        </Layout>
    )
}

export default TermsPage;